import React, { useEffect, useState, useCallback } from "react";
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { styled, makeStyles } from "@material-ui/core/styles";
import { Box, List, Typography } from "@material-ui/core";
import { colors } from "../../styles";
import { LabelTextInput, UnderlineTextInput } from "../../components/Inputs";
import iconAdd from "../../resources/icon-Plus-gray.svg";
import ChooseExerciseDialog from "../superSet/ChooseExerciseDialog";
import thumbnailPlaceholder from "../../resources/image-exercise-placeholder@2x.png";
import arrowIcon from "../../resources/icons-arrows-buttons-right-arrow-dark-gray.svg";
import nasmApi from "../../api/endpoints";

import Trash from '../../resources/trash.svg';
import { EXERCISE_TYPE, SAVE_STATES } from "../../constants";
import { CircleButton, OvalButton, SaveButton } from "../../components/Buttons";
import { editExercise, removeExercise, removeExerciseAndSaveWorkout } from "../../reducers/selectedWorkoutReducer";
import ConfirmDialog from "../../components/Dialogs/ConfirmDialog";
import { programContexts } from "../../reducers/programContextReducer";
import progressionIcon from '../../resources/progressionIcon.png';
import regressionIcon from '../../resources/regressionIcon.png';
import ROUTINE_TYPES from "../../util/RoutineTypes";

const useStyles = makeStyles({
  dialogPaper: {
    width: "554px",
    height: "600px",
    borderRadius: "5px",
    boxShadow: "0 3px 41px 0 rgba(0, 0, 0, 0.22)",
  },
  scrollPaper: {
    alignItems: "flex-start",
  },
  inputContainer: {
    padding: "20px",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "20px",
  },
  dialogHeading: {
    fontSize: "24px",
    fontWeight: 700,
  },
  buttonsBox: {
    display: "flex",
    flexDirection: "row",
  },
  text: {
    color: colors.steel,
    fontWeight: 400,
    fontSize: 14,
    marginBottom: "13px",
  },
  repsText:  {
    color: colors.steel,
    fontWeight: 400,
    fontSize: 14,
    marginTop: "13px",
  },
  restText:  ({ showError }) =>({
    color: colors.steel,
    fontWeight: 400,
    fontSize: 14,
    marginTop: showError? "28px" : "13px",
    marginBottom: "5px",
  }),
  repsInput: (props) => ({
    marginTop: props.isReadOnly ? "13px" : 0,
    marginBottom: props.isReadOnly ? "5px" : 0,
  }),
  labelInputStyles: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
  },
  chooseExerciseTxt: {
    color: colors.steel,
    fontWeight: 400,
    fontSize: 14,
    marginLeft: "20px",
    marginBottom: "10px",
  },
  addExerciseContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    border: `1px solid ${colors.divider_line_white}`,
    marginBottom: "15px",
    padding: "10px 20px",
  },
  addExerciseTxt: {
    fontWeight: 600,
    fontSize: 14,
  },
  addIcon: {
    width: "22px",
    height: "22px",
  },
  recordContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: 80,
    marginBottom: "20px",
    borderBottom: "1px solid #e6e6e6",
    borderLeft: "none",
    borderRight: "none",
    "&:hover": {
      backgroundColor: colors.baby_blue,
      cursor: "pointer",
    },
  },
  boxLayout: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  imageContainer: {
    paddingLeft: 12,
    display: "flex",
  },
  thumbnail: {
    borderRadius: 6,
    objectFit: "cover",
  },
  progressionRegressionView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    position: 'absolute',
    width: 76,
    height: 48,
    justifyContent: 'flex-end',
  },
  progressionRegressionIcon: {
    width: 17,
    height: 17,
    marginBottom: 3,
    marginRight: 3,
    marginLeft: 2,
  },
  nameContainer: {
    paddingLeft: 23,
    fontSize: 17,
    fontWeight: 500,
    color: colors.black,
  },
  arrowIconCont: {
    marginRight: 10,
  },
});

const labelInputStyles = {
  fontFamily: "Roboto, sans-serif",
  fontSize: "14px",
};


const DialogContainer = styled("div")({
  position: "relative",
});

function CreateCircuitDialog(props) {
  const {
    circuitData,
    setCircuitsData,
    backLink,
    resetSelection,
    viewCircuitExDetailsLink,
    isAutoSaveEnabled,
    isDeletable,
  } = props;

  const [showError, setShowError] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [saveState, setSaveState] = useState(SAVE_STATES.CLICKABLE);
  const [circuitsName, setCircuitsName] = useState("");
  const [reps, setReps] = useState("");
  const [rest, setRest] = useState("00:00");
  const [showChooseExerciseDialog, setShowChooseExerciseDialog] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedExerciseList, setSelectedExerciseList] = useState([]);
  const [circuitExercises, setCircuitExercises] = useState([]);
  const [isMounted, setIsMounted] = useState(true);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const sectionId = location?.state?.sectionId ?? null;
  const workoutExercise = location?.state?.exercise ?? {};
  const workout = location?.state?.workout ?? {};
  const programContext = useSelector(state => state.programContext.context);
  const currentUser = JSON.parse(localStorage.getItem('AUTH_TOKEN'));
  const isReadOnly = (programContext === programContexts.SCHEDULING) || currentUser?.cc_manager;
  const classes = useStyles({ showError, isReadOnly });

  const secondsToUnits = useCallback((time) => {
    let minutes = Math.floor(time / 60);
    let seconds = time % 60;

    if (seconds < 10) {
      seconds =  "0"+seconds;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    return handleRestInput(minutes + seconds);
  },[]);

  useEffect(() => {
    if (Object.keys(props.circuitData).length) {
      const exercise = props.circuitData;
      updateExercises(exercise);
      setCircuitsName(exercise.title);
      setReps(exercise.reps);
      secondsToUnits(exercise.rest);
      setIsUpdate(true);
    }

     return () => {
      setIsMounted(false); 
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, circuitData, secondsToUnits]);

  useEffect(() => {
    window.addEventListener(`progRegToggle_${ROUTINE_TYPES.CIRCUIT}`, onReceiveToggleEvent);

    return () => {
      window.removeEventListener(`progRegToggle_${ROUTINE_TYPES.CIRCUIT}`, onReceiveToggleEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onReceiveToggleEvent = (event) => {
    const { updatedData } = event.detail;
    if (updatedData) {
      updateExercises(updatedData);
    }
  };

  function updateExercises(circuit) {
    let compoundExercises = circuit.compound_routine_exercises ?? [];
    if (compoundExercises?.length && compoundExercises[0].exercise) {
      compoundExercises = circuit?.compound_routine_exercises?.map((ex) => {
        return {
          ...ex,
          ...ex.exercise,
        };
      });
    }
    setCircuitExercises(compoundExercises);
  }

  function onClose() {
    setSelectedExerciseList([]);
    setCircuitExercises([]);
    setCircuitsName("");
    setReps("");
    setRest("");
    setCircuitsData({});
  }

  const onSavePressed = () => {
    if (
      circuitsName &&
      Number(reps) > 0 &&
      timeToSeconds(rest) > 0 &&
      circuitExercises?.length &&
      circuitExercises.length > 0
    ) {
      setSaveState(SAVE_STATES.LOADING);
      const jsonData = {
        title: circuitsName.trim(),
        reps: Number(reps),
        rest: timeToSeconds(rest),
        exercises: circuitExercises.map((exercise, index) => ({
          exercise_id: exercise?.id,
          ordinal: index,
        })),
        routine_type: "circuit",
      };
      if (isUpdate) {
        jsonData.compound_routine_id = circuitData.id || circuitData.compound_routine_id;
        if (programContext === programContexts.RESCHEDULING) {
          updateCircuitLocally(jsonData);
        } else {
          updateCircuits(jsonData);
        }
      } else {
        createCircuits(jsonData);
      }
    } else {
      alert("Please fill all the details");
    }
  };

  const updateCircuitLocally = async (jsonData) => {
    const updatedCircuit = {
      ...jsonData,
      id: jsonData.compound_routine_id,
      key: circuitData?.key,
      compound_routine_exercises: circuitExercises.map((ex) => ({
        ...ex,
        ordinal: ex.id,
        compound_routine_id: jsonData.compound_routine_id,
      })),
    };
    try {
      setSaveState(SAVE_STATES.LOADING);
      await dispatch(editExercise(updatedCircuit));
      setSaveState(SAVE_STATES.SAVED);
    } catch (e) {
      setSaveState(SAVE_STATES.ERROR);
      window.alert(`Error saving changes to circuit: ${e.message}`);
    }
  };

  function onNameChanged(event) {
    const newInputValue = event.target.value;
    if (newInputValue !== circuitsName) {
      setCircuitsName(newInputValue);
    }
  }

  const onOpenChooseExerciseDialog = () => {
    setSelectedExerciseList(circuitExercises);
    setShowChooseExerciseDialog(true);
  };

  const onDoneChooseExerciseDialog = () => {
    setShowChooseExerciseDialog(false);
    setCircuitExercises(selectedExerciseList);
  };

  const onCloseChooseExerciseDialog = () => {
    setShowChooseExerciseDialog(false);
  };

  function onOpenConfirmationDialog () {
    setShowConfirmDeleteDialog(true);
  }

  function onCloseConfirmationDialog () {
    setShowConfirmDeleteDialog(false);
  }

  function onPressDelete () {
    onCloseConfirmationDialog();
    if (sectionId == null) {
      window.alert(`Failed to delete circuit. Could not find 
      ${workoutExercise?.title ?? 'this circuit'} in any of the` +
        ` workout sections in ${workout?.name ?? 'this workout'}`);
    } else {
      // When a workout is duplicated, the trainer must manually save the workout
      if (isAutoSaveEnabled) {
        dispatch(removeExerciseAndSaveWorkout({ exercise: workoutExercise, sectionId }))
          .then(() => {
            setShowConfirmDeleteDialog(false);
            resetSelection();
            history.push(backLink());
          })
          .catch((e) => {
            console.error(`Failed to delete circuit error:\n${JSON.stringify(e, null, 2)}`);
            window.alert(`Failed to delete the circuit: 
            ${workoutExercise.title} from ${workout?.name ?? 'this workout'}`);
          });
      } else {
        dispatch(removeExercise({ exercise: workoutExercise, sectionId }));
        history.goBack();
      }
    }
  }

  const onSelectedExerciseChange = (exercise) => {
    const selecteIds = selectedExerciseList?.map(option => option.id);
    if (selecteIds && selecteIds.includes(exercise.id)) {
      const updatedArray = selectedExerciseList?.filter(option => option.id !== exercise.id);
      setSelectedExerciseList(updatedArray);
    } else {
      if (selectedExerciseList) {
        setSelectedExerciseList(prevState => [...prevState, exercise]);
      } else {
        setSelectedExerciseList([exercise]);
      }
    }
  };

  const createCircuits = async (jsonData) => {
    await nasmApi.supersets
      .createSuperSet(jsonData)
      .then((res) => {
        if (res) {
          setSaveState(SAVE_STATES.SAVED);
          alert("Circuit created successfully");
          onClose();
          history.replace(backLink());
          window.location.reload();
        } else {
          alert("Something went wrong! Please try again later.");
          setSaveState(SAVE_STATES.ERROR);
        }
      })
      .catch((err) => {
        setSaveState(SAVE_STATES.ERROR);
        alert(err?.message || "Something went wrong! Please try again later.");
      });
  };

  const updateCircuits = async (jsonData) => {
    await nasmApi.supersets
      .updateSuperSetById(jsonData)
      .then((res) => {
        if (res) {
          setSaveState(SAVE_STATES.SAVED);
          alert("Circuit updated successfully");
          history.replace(backLink());
          if (window.location.pathname.includes('/libraries/exercises')) {
            window.location.reload();
          }
        } else {
          alert("Something went wrong! Please try again later.");
          setSaveState(SAVE_STATES.ERROR);
        }
      })
      .catch((err) => {
        setSaveState(SAVE_STATES.ERROR);
        alert(err?.message || "Something went wrong! Please try again later.");
      });
  };

  const handleRoundsInput = (event) => {
    const value = event.target.value;
    if (value.length < 3) {
      if (value < 100) {
        setReps(value);
        setShowError(false);
      } else {
        setShowError(true);
      }
    }
  };

  const  timeToSeconds = (time) => {
    // const formated = this.formatRestTime(time);
    const [minutes, seconds] = time.split(':');
    return parseInt(minutes, 10) * 60 + parseInt(seconds, 10);
  };



  const handleRestInput = (value) => {
    let parsed = 0;
    let rest = '';
     if (typeof value === 'string') {
      parsed = parseInt(value.replace(':', ''), 10);
    } else {
       return;
    }
    const digits = parsed.toString().split('');
    switch (digits.length) {
      case 1:
        rest = '00:0'+digits.join("");
        // setRest(rest);
        break;
      case 2:
         rest = '00:'+digits.join("");
         break;
      case 3:
         rest = '0'+digits.shift()+':'+digits.join('');
        break;
      case 4: {
        const minutes = digits.slice(0, 2).join('');
        const seconds = digits.slice(2).join('');
         rest = minutes+":"+seconds;
      }
        break;
      case 5: {
        const minutes = digits.slice(0, 2).join('');
        const seconds = digits.slice(2, 4).join('');
         rest = minutes+':'+seconds;
      }
        break;
      default:
         rest = (value);
    }

    setRest(rest);

  };

  const renderChooseExercise = () => (
    <ChooseExerciseDialog
      isOpen={showChooseExerciseDialog}
      onClose={onCloseChooseExerciseDialog}
      onDone={onDoneChooseExerciseDialog}
      setSelectedExercise={(exercise) => onSelectedExerciseChange(exercise)}
      setSelectedCircuitsExercise={selectedExerciseList? selectedExerciseList.map(option => option.id): []}
      isCircuits={true}
    />
  );

  const renderButtonContainer = () => {
    return (
      <Box className={classes.buttonsContainer}>
        {!currentUser?.cc_manager && (isDeletable || programContext === programContexts.RESCHEDULING) ?
          <CircleButton onClick={onOpenConfirmationDialog}>
            <img alt='trash icon' src={Trash} />
          </CircleButton> : null
        }
        <Typography className={classes.dialogHeading}>
          {isReadOnly ? 'Circuit Details' 
            : isUpdate ? 'Update Circuit' : 'Create Circuit'}
        </Typography>
        <Box className={classes.buttonsBox}>
          <Box style={{ paddingRight: 5 }}>
          {!isReadOnly ? <SaveButton
              saveState={saveState}
              onClick={onSavePressed}
            /> : null}
          </Box>
          <Link to={{ search: backLink() }}>
            <Box>
              <OvalButton onClick={resetSelection}>Close</OvalButton>
            </Box>
          </Link>
        </Box>
      </Box>
    );
  };

  const renderExerciseList = () => {
    return (
      <List>
        {circuitExercises?.map((exercise, index) => {
          return renderExercise(exercise);
        })}
      </List>
    );
  };

  const renderProgressionRegressionIconImage = (exercise) => {
    const hasProgression = exercise?.progressions_regressions?.some(
      (ex) => ex.exerciseType === EXERCISE_TYPE.PROGRESSION,
    );
    const hasRegression = exercise?.progressions_regressions?.some(
      (ex) => ex.exerciseType === EXERCISE_TYPE.REGRESSION,
    );

    return (
      <Box className={classes.progressionRegressionView}>
        {hasProgression ? (
          <img
            src={progressionIcon}
            className={classes.progressionRegressionIcon}
            alt={'Prog'}
            onError={(e) => {
              e.target.src = progressionIcon;
            }}
          />
        ) : null}
        {hasRegression ? (
          <img
            src={regressionIcon}
            className={classes.progressionRegressionIcon}
            alt={'Reg'}
            onError={(e) => {
              e.target.src = regressionIcon;
            }}
          />
        ) : null}
      </Box>
    );
  };


  const renderExercise = (exercise) => {
    if (Object.keys(exercise).length === 0) return null;
    return (
      <Link to={{ search: viewCircuitExDetailsLink(exercise.id, circuitData.key)}}>
      <Box className={classes.recordContainer} key={exercise.id}>
        <Box className={classes.boxLayout}>
          <div className={classes.imageContainer}>
            <img
              width={75}
              height={48}
              alt="exercise-thumbnail"
              src={exercise.image_url || thumbnailPlaceholder}
              className={classes.thumbnail}
            />
            {exercise?.progressions_regressions?.length
              ? renderProgressionRegressionIconImage(exercise)
              : null}
          </div>
          <div className={classes.nameContainer}>
            {exercise.name || "Exercise Name"}
          </div>
        </Box>
        <Box className={classes.arrowIconCont}>
          <img width={26} height={25} alt="arrow" src={arrowIcon} />
        </Box>
      </Box>
      </Link>
    );
  };

  return (
    <>
      <DialogContainer>
        <Box className={classes.inputContainer}>
          {renderButtonContainer()}
          <Typography className={classes.text}>Circuits Name</Typography>
          <UnderlineTextInput
            value={circuitsName || ""}
            placeholder="Enter Circuits Name"
            onNameChanged={onNameChanged}
            readOnly={isReadOnly}
          />
          <Box className={classes.repsInput}>
            {!isReadOnly ? <Typography className={classes.repsText}>
              Enter a maximum of 99 Rounds
            </Typography> : null}
            <LabelTextInput
              placeholder="Enter round(s)"
              label="Rounds:"
              value={reps}
              onHandleChange={(e) => handleRoundsInput(e)}
              onHandleBlur={(e) => {}}
              labelStyles={labelInputStyles}
              errorText="Enter a maximum of 99 Rounds"
              showError={showError}
              fontFamily="Roboto, sans-serif"
              fontSize={14}
              width={512}
              height={40}
              isDisabled={isReadOnly || false}
            />
            <Typography className={classes.restText}></Typography>
            <LabelTextInput
              placeholder="Enter rest period"
              label="Rest:"
              value={rest}
              onHandleChange={(e) => handleRestInput(e.target.value)}
              onHandleBlur={(e) => {}}
              labelStyles={labelInputStyles}
              fontFamily="Roboto, sans-serif"
              fontSize={14}
              width={512}
              height={40}
              isDisabled={isReadOnly || false}
            />
          </Box>
        </Box>
        <Box>
          <Typography className={classes.chooseExerciseTxt}>
            Exercises
          </Typography>
          {!isReadOnly && <Box className={classes.addExerciseContainer}>
            <Typography className={classes.addExerciseTxt}>
              Exercise
            </Typography>
            <Box onClick={() => onOpenChooseExerciseDialog(0)}>
                <img src={iconAdd} alt="add icon" className={classes.addIcon} />
            </Box>
          </Box>}
          {renderExerciseList()}
        </Box>
      </DialogContainer>
      {renderChooseExercise()}
      <ConfirmDialog
        title='Remove Circuit'
        actionButtonTitle='Delete'
        description={`Are you sure you would like to remove "${workoutExercise?.title ?? 'this circuit'}"?`}
        open={showConfirmDeleteDialog}
        onClose={onCloseConfirmationDialog}
        handleConfirmAction={onPressDelete}
      />
    </>
  );
}

export default CreateCircuitDialog;
