/* eslint-disable camelcase */
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { colors } from '../../styles';
import SectionDivider from './SectionDivider';

import thumbnailPlaceholder from '../../resources/image-exercise-placeholder@2x.png';
import progressionIcon from '../../resources/progressionIcon.png';
import regressionIcon from '../../resources/regressionIcon.png';
import linkIcon from '../../resources/linked_icon.png';
import { combineExercisesAndSuperSets } from '../../util/programUtils';
import ROUTINE_TYPES from '../../util/RoutineTypes';
import { EXERCISE_TYPE } from '../../constants';

export default function WorkoutsList (props) {
  const { sections = [] } = props;
  const canDisplayExercisesDivider = doesOneSectionContainExercises(sections);

  return (
    <Box style={{ overflowY: 'auto' }}>
      {sections.map(s => (
        <ExerciseList
          key={s.id}
          section={s}
          canDisplayExercisesDivider={canDisplayExercisesDivider}
        />
      ))}
    </Box>
  );
}

const useExerciseStyle = makeStyles({
  exerciseRowCont: (props) => ({
    border: props.exercise?.routine_type === ROUTINE_TYPES.SUPER_SET ? `2px solid ${colors.lime_green}`
    : props.exercise?.routine_type === ROUTINE_TYPES.CIRCUIT ? `2px solid ${colors.yellow}`
    : 'none',
    margin: props.isSuperSetOrCircuit ? '5px' : 0,
  }),
  exercise: (props) => ({
    display: 'flex',
    alignItems: 'center',
    borderBottom: props.hideBorder ? 'none' : `1px solid ${colors.colors_fill_light_gray_2}`,
    borderCollapse: 'collapse',
    backgroundColor: props.exercise?.routine_type === ROUTINE_TYPES.SUPER_SET
      ? colors.mildGreenWithOpacity : props.exercise?.routine_type === ROUTINE_TYPES.CIRCUIT
      ? colors.mildYellowWithOpacity : colors.white,
    padding: props.isSuperSetOrCircuit ? 20 : 0,
    paddingLeft: 20,
    paddingRight: 20,
  }),
  exerciseNameCont: (props) => ({
    width: props.isSuperSetOrCircuit ? '85%' : '65%',
  }),
  exerciseName: (props) => ({
    fontSize: 17,
    fontWeight: 500,
    lineHeight: 1.71,
    width: props.isSuperSetOrCircuit ? '85%' : '65%',
    textAlign: 'left',
    marginLeft: props.isSuperSetOrCircuit ? 0 : '20px',
  }),
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  thumbnail: {
    borderRadius: 6,
  },
  supersetIconBox: (props) => ({
    backgroundColor: props.exercise?.routine_type === ROUTINE_TYPES.SUPER_SET ? colors.lime_green
    : props.exercise?.routine_type === ROUTINE_TYPES.CIRCUIT ? colors.yellow 
    : colors.lime_green,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 3,
    paddingLeft: 10,
    paddingRight: 10,
  }),
  supersetText: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.71,
    color: colors.white,
  },
  supersetDetails: {
    display: 'flex',
    flexDirection: 'row',
    gap: 3,
  },
  progressionRegressionView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    position: 'absolute',
    width: 76,
    height: 48,
    justifyContent: 'flex-end',
  },
  progressionRegressionIcon: {
    width: 17,
    height: 17,
    marginBottom: 3,
    marginRight: 3,
    marginLeft: 2,
  },
  imageContainer: {
    paddingLeft: 12,
    display: 'flex',
    position: 'relative',
  },
});

function ExerciseRow (props) {
  const { exercise = {}, hideBorder = false } = props;
  const {
    name = 'Exercise Name',
    image_url: imageUrl,
    reps,
    sets,
    dur_seconds: durSeconds,
    distance,
    distance_units: distanceUnits,
    pace,
    pace_units: paceUnits,
  } = exercise;

  const isSuperSetOrCircuit = 'compound_routine_exercises' in exercise;

  const renderProgressionRegressionIconImage = (exercise) => {
    const hasProgression = exercise?.progressions_regressions?.some(
      (ex) => ex.exerciseType === EXERCISE_TYPE.PROGRESSION,
    );
    const hasRegression = exercise?.progressions_regressions?.some(
      (ex) => ex.exerciseType === EXERCISE_TYPE.REGRESSION,
    );

    return (
      <Box className={classes.progressionRegressionView}>
        {hasProgression ? (
          <img
            src={progressionIcon}
            className={classes.progressionRegressionIcon}
            alt={'Prog'}
            onError={(e) => {
              e.target.src = progressionIcon;
            }}
          />
        ) : null}
        {hasRegression ? (
          <img
            src={regressionIcon}
            className={classes.progressionRegressionIcon}
            alt={'Reg'}
            onError={(e) => {
              e.target.src = regressionIcon;
            }}
          />
        ) : null}
      </Box>
    );
  };

  const classes = useExerciseStyle({ exercise, isSuperSetOrCircuit, hideBorder });
  let setsCount = 0;
  let totalDuration = 0;
  let routineType = '';
  if (isSuperSetOrCircuit && exercise?.compound_routine_exercises) {
    for (const ex of exercise?.compound_routine_exercises) {
      if (Number.isInteger(ex.sets)) {
        setsCount += ex.sets;
        totalDuration += ex.dur_seconds;
      }
    }
  }
  if (isSuperSetOrCircuit && exercise?.routine_type) {
    routineType = exercise?.routine_type === ROUTINE_TYPES.SUPER_SET ? 'Superset' : 'Circuit';
  }

  return (
    <Box className={classes.exerciseRowCont}>
      <Box className={classes.exercise}>
        <Box className={classes.imageContainer}>
        {!isSuperSetOrCircuit ? (
          <img
            className={classes.thumbnail}
            alt='Exercise Thumbnail'
            width={75}
            height={48}
            src={imageUrl || thumbnailPlaceholder}
          />
        ) : null}
          {exercise?.progressions_regressions?.length
            ? renderProgressionRegressionIconImage(exercise)
            : null}
        </Box>
        <Box className={classes.exerciseNameCont}>
          <Typography className={classes.exerciseName}>
            {(isSuperSetOrCircuit ? exercise?.title : name) || 'Unnamed Exercise'}
          </Typography>
          {setsCount || totalDuration ? (
            <Box className={classes.supersetDetails}>
              <TimeText durSeconds={totalDuration} />
              <DetailsText label='set' value={setsCount} />
            </Box>) : null}
        </Box>
        {isSuperSetOrCircuit ? (
          <Box className={classes.supersetIconBox}>
            <Typography className={classes.supersetText}>{routineType}</Typography>
            <img
              alt='link icon'
              width={20}
              height={20}
              src={linkIcon}
            />
          </Box>) : null}
        {!isSuperSetOrCircuit ? (
          <Box style={{ display: 'flex'}}>
            <VerticalLine />
            <Box className={classes.details}>
              <DetailsText label='set' value={sets} />
              <DetailsText label='rep' value={reps} />
              <DetailsText label={distanceUnits} value={distance} />
              <DetailsText label={paceUnits} value={pace} />
              <TimeText durSeconds={durSeconds} />
            </Box>
          </Box>
        ) : null}
      </Box>
      {isSuperSetOrCircuit && exercise?.compound_routine_exercises?.map(e => (
        <ExerciseRow key={e.id} exercise={e} hideBorder={true}/>
      ))}
  </Box>
  );
}

const useDetailsTextStyle = makeStyles({
  detailsText: {
    fontSize: 12,
    fontFamily: 'Roboto',
    lineHeight: 2.25,
    color: colors.steel,
  },
});

/**
 * @return {null | JSX}
 */
function DetailsText (props) {
  const { value, label } = props;
  const classes = useDetailsTextStyle();

  if (!value) {
    return null;
  }

  const finalLabel = value > 1 || value === 0 ? `${label}s` : label;

  return (
    <Typography className={classes.detailsText}>
      {`${value} ${finalLabel}`}
    </Typography>
  );
}

function TimeText (props) {
  const { durSeconds } = props;
  const classes = useDetailsTextStyle();

  if (!durSeconds) {
    return null;
  }

  const minutes = Math.floor(durSeconds / 60);
  const seconds = durSeconds % 60;

  const displayTimeMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const displayTimeSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  const displayTime = `${displayTimeMinutes}:${displayTimeSeconds}`;

  return (
    <Typography className={classes.detailsText}>
      {displayTime}
    </Typography>
  );
}

const VerticalLine = withStyles({
  root: {
    width: 1,
    height: 56,
    margin: '23px 14px 25px 20px',
    opacity: 0.5,
    border: `solid 1px ${colors.colors_fill_light_gray_2}`,
  },
})(Box);

function ExerciseList (props) {
  const { canDisplayExercisesDivider, section = {} } = props;
  const { name, exercises, scheduled_exercises } = section;

  let scheduledExercises = exercises || scheduled_exercises || [];

  if (scheduledExercises.length === 0) {
    return null;
  }

  const sectionExercises = combineExercisesAndSuperSets(scheduledExercises);

  return (
    <>
      <SectionDivider name={canDisplayExercisesDivider ? 'Exercises' : name} />
      {sectionExercises.map(e => {
        return (
          <ExerciseRow key={e.id} exercise={e} />
        );
      })}
    </>
  );
}

// HELPER FUNCTION(S)
function doesOneSectionContainExercises (sections) {
  // check if exactly 1 section contains 1 or more exercises
  let sectionsWithExercisesCount = 0;
  for (const section of sections) {
    if (section.exercises && section.exercises.length > 0) {
      ++sectionsWithExercisesCount;
    }
  }

  return sectionsWithExercisesCount === 1;
}
