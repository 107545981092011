import React, { useRef } from 'react';
import { Box, List, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../styles';
import linkIcon from '../../resources/linked_icon.png';
import ROUTINE_TYPES from '../../util/RoutineTypes';
import thumbnailPlaceholder from '../../resources/image-exercise-placeholder@2x.png';
import progressionIcon from '../../resources/progressionIcon.png';
import regressionIcon from '../../resources/regressionIcon.png';
import arrowRight from "../../resources/icons-arrows-buttons-right-arrow-dark-gray.svg";
import { EXERCISE_TYPE } from '../../constants';
import { calculateSecondsForExercise, getDurationFromSeconds } from '../../util/programUtils';

const useListStyle = makeStyles({
  list: {
    // hides scrollbar if mouse isn't hovering over the list
    overflowY: 'hidden',
    height: '70%',
    '&:hover': {
      overflowY: 'auto',
    },
  },
});

const useExerciseStyle = makeStyles({
  exerciseRowCont: (props) => ({
    border: props.exercise?.routine_type === ROUTINE_TYPES.SUPER_SET ? `2px solid ${colors.lime_green}` 
    : props.exercise?.routine_type === ROUTINE_TYPES.CIRCUIT ? `2px solid ${colors.yellow}` : 'none',
    margin: props.isSuperSetOrCircuit ? '5px' : 0,
  }),
  exercise: (props) => ({
    display: 'flex',
    alignItems: 'center',
    borderBottom: props.hideBorder ? 'none' : `1px solid ${colors.colors_fill_light_gray_2}`,
    borderCollapse: 'collapse',
    backgroundColor: props.exercise?.routine_type === ROUTINE_TYPES.SUPER_SET
      ? colors.mildGreenWithOpacity : props.exercise?.routine_type === ROUTINE_TYPES.CIRCUIT
        ? colors.mildYellowWithOpacity : colors.white,
    padding: props.isSuperSetOrCircuit ? 20 : 0,
    paddingLeft: 20,
    paddingRight: 20,
    height: 100,
    '&:hover': {
      backgroundColor: props.isSuperSetOrCircuit ? 'none' : colors.baby_blue,
      cursor: props.isSuperSetOrCircuit ? 'cursor' : 'pointer',
    },
    '&:active': {
      filter: props.isSuperSetOrCircuit ? 'none' : 'brightness(88%)',
      backgroundColor: props.isSuperSetOrCircuit ? 'none' : colors.white,
    },
  }),
  exerciseNameCont: (props) => ({
    width: props.isSuperSetOrCircuit ? '95%' : '95%',
  }),
  exerciseName: (props) => ({
    fontSize: 17,
    fontWeight: 500,
    lineHeight: 1.71,
    width: props.isSuperSetOrCircuit ? '95%' : '95%',
    textAlign: 'left',
    marginLeft: props.isSuperSetOrCircuit ? 0 : '20px',
  }),
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  thumbnail: {
    borderRadius: 6,
  },
  supersetIconBox: (props) => ({
    backgroundColor: props.exercise?.routine_type === ROUTINE_TYPES.SUPER_SET ? colors.lime_green 
    : props.exercise?.routine_type === ROUTINE_TYPES.CIRCUIT ? colors.yellow : 'none',
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 3,
    paddingLeft: 10,
    paddingRight: 10,
  }),
  supersetText: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.71,
    color: colors.white,
  },
  supersetDetails: {
    display: 'flex',
    flexDirection: 'row',
    gap: 3,
  },
  progressionRegressionView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    position: 'absolute',
    width: 76,
    height: 48,
    justifyContent: 'flex-end',
  },
  progressionRegressionIcon: {
    width: 17,
    height: 17,
    marginBottom: 3,
    marginRight: 3,
    marginLeft: 2,
  },
  imageContainer: {
    paddingLeft: 12,
    display: 'flex',
  },
  rightArrow: {
    height: "22px",
    width: "22px",
    objectFit: "cover",
  },
});

const useDetailsTextStyle = makeStyles({
  detailsText: {
    fontSize: 12,
    fontFamily: 'Roboto',
    lineHeight: 2.25,
    color: colors.steel,
  },
});

function DetailsText(props) {
  const { value, label } = props;
  const classes = useDetailsTextStyle();

  if (!value) {
    return null;
  }

  const finalLabel = value > 1 || value === 0 ? `${label}s` : label;

  return (
    <Typography className={classes.detailsText}>
      {`${value} ${finalLabel}`}
    </Typography>
  );
}

function ExerciseRow(props) {
  const { exercise = {}, hideBorder = false, viewExerciseDetailsLink, exerciseKey } = props;
  const {
    name = 'Exercise Name',
    image_url: imageUrl,
  } = exercise;

  const isSuperSetOrCircuit = 'compound_routine_exercises' in exercise;
  const history = useHistory();
  const location = history.location;

  const renderProgressionRegressionIconImage = (exercise) => {
    const hasProgression = exercise?.progressions_regressions?.some(
      (ex) => ex.exerciseType === EXERCISE_TYPE.PROGRESSION,
    );
    const hasRegression = exercise?.progressions_regressions?.some(
      (ex) => ex.exerciseType === EXERCISE_TYPE.REGRESSION,
    );

    return (
      <Box className={classes.progressionRegressionView}>
        {hasProgression ? (
          <img
            src={progressionIcon}
            className={classes.progressionRegressionIcon}
            alt={'Prog'}
            onError={(e) => {
              e.target.src = progressionIcon;
            }}
          />
        ) : null}
        {hasRegression ? (
          <img
            src={regressionIcon}
            className={classes.progressionRegressionIcon}
            alt={'Reg'}
            onError={(e) => {
              e.target.src = regressionIcon;
            }}
          />
        ) : null}
      </Box>
    );
  };

  const classes = useExerciseStyle({ exercise, isSuperSetOrCircuit, hideBorder });
  let setsCount = 0;
  let totalDuration = 0;
  let routineType = '';
  if (isSuperSetOrCircuit && exercise?.compound_routine_exercises) {
    for (const ex of exercise?.compound_routine_exercises) {
      if (Number.isInteger(ex?.sets)) {
        setsCount += ex?.sets;
      }
    }
    totalDuration = getDurationForSection(exercise?.compound_routine_exercises);
  }
  if (isSuperSetOrCircuit && exercise?.routine_type) {
    routineType = exercise?.routine_type === ROUTINE_TYPES.SUPER_SET ? 'Superset' : 'Circuit';
  }

  const handleExerciseClick = () => {
    if(isSuperSetOrCircuit) {
      return;
    }
    history.push({
      pathname: location.pathname,
      search: viewExerciseDetailsLink(exercise.id, exerciseKey),
    });
  };

  return (
    <Box className={classes.exerciseRowCont}>
      <Box className={classes.exercise} onClick={() => handleExerciseClick()}>
        <div className={classes.imageContainer}>
          {!isSuperSetOrCircuit ? (
            <img
              className={classes.thumbnail}
              alt='Exercise Thumbnail'
              width={75}
              height={48}
              src={imageUrl || thumbnailPlaceholder}
            />
          ) : null}
          {exercise?.progressions_regressions?.length
            ? renderProgressionRegressionIconImage(exercise)
            : null}
        </div>
        <Box className={classes.exerciseNameCont}>
          <Typography className={classes.exerciseName}>
            {(isSuperSetOrCircuit ? exercise?.title : name) || 'Unnamed Exercise'}
          </Typography>
          {setsCount || totalDuration ? (
            <Box className={classes.supersetDetails}>
              <DetailsText label='' value={totalDuration} />
              <DetailsText label='set' value={setsCount} />
            </Box>) : null}
        </Box>
        {isSuperSetOrCircuit ? (
          <Box className={classes.supersetIconBox}>
            <Typography className={classes.supersetText}>{routineType}</Typography>
            <img
              alt='link icon'
              width={20}
              height={20}
              src={linkIcon}
            />
          </Box>) : null}
        {!isSuperSetOrCircuit ? (
          <Box style={{ display: 'flex' }}>
            <img
                className={classes.rightArrow}
                src={arrowRight}
                alt="Avatar"
                onError={(e) => {
                  e.target.src = arrowRight;
                }}
              />
          </Box>
        ) : null}
      </Box>
      {isSuperSetOrCircuit && exercise?.compound_routine_exercises?.map(e => (
        <ExerciseRow 
          key={e.id} 
          exercise={e} 
          exerciseKey={exercise.key}
          hideBorder={true} 
          viewExerciseDetailsLink={viewExerciseDetailsLink} 
        />
      ))}
    </Box>
  );
}

function getDurationForSection (exercises = []) {
  let seconds = 0;
  let appendPlus = false;

  exercises.forEach((exercise) => {
    const exerciseSeconds = calculateSecondsForExercise(exercise);
    seconds += exerciseSeconds;
    if (exerciseSeconds === 0) {
      appendPlus = true;
    }
  });

  return getDurationFromSeconds(seconds, appendPlus);
}

export default function AddingToWorkoutExercisesList(props) {
  const {
    exerciseData,
    viewExerciseDetailsLink,
  } = props;

  const rootElement = useRef();

  const classes = useListStyle();
  const exercises = Object.values(exerciseData);

  return (
    <List ref={rootElement} className={classes.list}>
      {exercises?.map((exercise, index) => {
        return (
          <ExerciseRow
            key={exercise.id}
            exercise={exercise}
            exerciseKey={exercise.key}
            viewExerciseDetailsLink={viewExerciseDetailsLink}
          />
        );
      })}
    </List>
  );
}
