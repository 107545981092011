/* eslint-disable camelcase */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { colors } from "../../styles";
import placeholderIcon from "../../resources/user-dashboard-client-profile-with-no-photo.svg";
import editIcon from "../../resources/edit-orange.png";

const useStyles = makeStyles({
  root: {
    borderRadius: "10px",
    border: `1px solid ${colors.selected_highlight_copy_6}`,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    paddingBottom: 10,
    marginBottom: 30,
    width: "30%",
  },
  container: {
    display: "flex",
  },
  nameView: {
    marginLeft: 20,
  },
  groupName: {
    fontWeight: 500,
    fontSize: 17,
    color: colors.black,
    fontFamily: "DMSans-Medium, sans-serif",
    marginTop: 4,
  },
  count: {
    fontSize: 12,
    color: colors.colors_fill_light_gray_2,
    fontFamily: "DMSans-Medium, sans-serif",
    marginTop: 2,
  },
  profileImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "18px",
  },
  nameContainer: {
    display: "flex",
    width: "70%",
  },
  actionContainer: {
    display: "flex",
    width: "30%",
    justifyContent: "flex-end",
  },
  iconView: (props) => ({
    columns: props.clientCount ? "2 auto" : "1",
    columnGap: 10,
  }),
  avatarBox: {
    width: "36px",
    height: "36px",
    objectFit: "cover",
    borderRadius: "18px",
    marginBottom: 10,
  },
  chatView: {
    borderRadius: "17px",
    width: "34px",
    height: "34px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    border: `1px solid ${colors.selected_highlight_copy_6}`,
    marginRight: 10,
  },
  editIconBtn: {
    width: "40px",
    height: "40px",
  },
  editIcon: {
    width: "100%",
    height: "100%",
  },
  cursorStyle: {
    cursor: 'pointer',
    '&:hover': {
      filter: 'brightness(90%)',
    },
  },
});

function GroupView(props) {
  const { group, onClick, onClickEdit } = props;
  const { title, client_group_clients, id } = group;

  const filtered_clients = [...client_group_clients];
  if (filtered_clients.length > 4) {
    filtered_clients.splice(4, filtered_clients.length - 4);
  }

  const classes = useStyles({ clientCount: filtered_clients.length > 1 });
  const currentUser = JSON.parse(localStorage.getItem('AUTH_TOKEN'));

  return (
    <Box
      key={id}
      className={classes.root}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <Box className={classes.container}>
        <Box className={[classes.nameContainer, classes.cursorStyle]}>
          <Box className={classes.iconView}>
            {filtered_clients.map((item, index) => (
              <Box key={index} className={classes.avatarBox}>
                <img
                  className={classes.profileImg}
                  src={
                    item.client_user?.user?.avatar_url
                      ? item.client_user?.user?.avatar_url
                      : placeholderIcon
                  }
                  onError={(e) => {
                    e.target.src = placeholderIcon;
                  }}
                  alt="profile"
                />
              </Box>
            ))}
          </Box>
          <Box className={classes.nameView}>
            <Typography className={classes.groupName}>{title}</Typography>
            <Typography
              className={classes.count}
            >{`${client_group_clients?.length} members`}</Typography>
          </Box>
        </Box>
        { !currentUser?.cc_manager ? (
        <Box className={classes.actionContainer}>
          <Box
            className={[classes.editIconBtn, classes.cursorStyle]}
            onClick={(e) => {
              e.stopPropagation();
              onClickEdit();
            }}
          >
            <img
              className={classes.editIcon}
              src={editIcon}
              alt="edit"
            />
          </Box>
        </Box> ) : null }
      </Box>
    </Box>
  );
}

export default GroupView;
